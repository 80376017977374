/**
navbar
*************************************************************/
.navbar-toggler {
    position: absolute;
    top: 30px;
    left: 30px;
    border: none !important;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar {

    background-color: #fff;

    .nav-link {
        font-style: normal;
        font-weight: normal;
        font-size: 1.7rem;
        line-height: 30px;
        padding-top: 17px;
        white-space: nowrap;
    }

    @include media-breakpoint-down(md) {

        background-color: map-get($map: $theme-colors, $key: 'primary');

        #navbar-right {
            padding-bottom: 30px;
        }
    }
}

.navbar-brand {

    padding: 14px 43px 24px 43px;
    text-align: center;
    background-color: map-get($map: $theme-colors, $key: 'primary');
    margin: 0 34px;
    width: 350px;

    @include media-breakpoint-down(lg) {
        padding: 14px 33px 24px;
    }

    @include media-breakpoint-down(md) {
        padding: 15px 0;
    }

    img {
        width: 264px;

        @include media-breakpoint-down(lg) {
            width: 230px;
        }

        @include media-breakpoint-down(md) {
            width: 195px;
        }
    }
}