// Color system
$theme-colors: ("primary": #6C750F);

// Options
$enable-rounded: false;
$enable-shadows: false;
$enable-gradients: false;

$link-hover-decoration: none;
$link-color: #000;
$link-hover-color: map-get($map: $theme-colors, $key: 'primary');

//Paddings
$default-margin: 15px;

// Typography

$font-size-base: 1.5rem;
$line-height-base: 2;
$font-family-sans-serif: "Source Sans Pro";
$fa-font-path: '/files/assets/dist/fonts/fontawesome/';

$paragraph-margin-bottom: 26px;
$headings-margin-bottom: 26px;

// Slick

$slick-font-path: "/files/assets/dist/fonts/slick/" !default;
$slick-loader-path: "/files/assets/dist/img/" !default;

$slick-font-family: 'fontawesome';
$slick-arrow-color: #000;
$slick-prev-character: "";
$slick-next-character: "";
$slick-dot-character: "\f111";
$slick-dot-size: 1.2rem;

// Navbar

$navbar-padding-y: 0;
$navbar-nav-link-padding-x: $default-margin;

$navbar-light-color: #000;
$navbar-light-active-color:  #000; // map-get($map: $theme-colors, $key: 'primary');
$navbar-light-hover-color: map-get($map: $theme-colors, $key: 'primary');
// $navbar-brand-padding-y: 20px;

// Navs

// $nav-link-padding-y:                1.5rem;