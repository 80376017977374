@import 'variables';

@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../../node_modules/slick-carousel/slick/slick.scss';
@import '../../../node_modules/slick-carousel/slick/slick-theme.scss';

// @import '../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
// @import '../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss';

@import '../../../node_modules/font-awesome/scss/font-awesome.scss';

@import 'fonts';
@import 'navbar';

/**
Basics
*************************************************************/
html {
    font-size: 62.5%;
}

html,
body {
    height: 100%;
}

body {
    margin-top: 0;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
    outline: none !important;
}

.invisible {
    display: none;
}

.widget-submit {
    margin-top: $default-margin;
    margin-bottom: $default-margin;
}

a.btn-primary {
    color: #ffffff !important;
}

figure {
    margin-bottom: 0;

    &.float_above {
        margin-bottom: $paragraph-margin-bottom * 2;
    }
}

.bg-light {
    background-color: #f6f6f6 !important;
}

h1,
.h1,
h2,
.h2 {
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 28px;
}


/**
mod_article
*************************************************************/

#container {
    padding-top: 120px;

    @include media-breakpoint-down(lg) {
        padding-top: 109px;
    }

    @include media-breakpoint-down(md) {
        padding-top: 90px;
    }
}

.mod_article {
    &.bg-light {
        padding-top: 77px;
        padding-bottom: 77px;
    }

    div[class*=" col-"] {
        p:last-of-type() {
            margin-bottom: 0;
        }
    }
}

.container-fluid {
    max-width: 1400px;
}

/**
wilkommen
*************************************************************/

#willkommen,
#kontakt {

    .ce_text {
        padding-top: $paragraph-margin-bottom;
        padding-bottom: $paragraph-margin-bottom;
    }

    figure {
        margin-bottom: 0;

        &.float_above {
            margin: 0 auto $paragraph-margin-bottom auto;

            @include media-breakpoint-down(md) {
                max-width: 50%;
            }
        }

        @include media-breakpoint-down(sm) {
            margin-left: -15px;
            margin-right: -15px;
        }
    }

    iframe {
        height: 670px;

        @include media-breakpoint-down(md) {
            height: 370px;
        }
    }
}

/**
öffnungszeiten
*************************************************************/
#oeffnungszeiten {

    .ce_text {
        @include media-breakpoint-down(sm) {
            padding-bottom: 77px;
        }
    }

    figure {
        @include media-breakpoint-down(sm) {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
}

/**
events
*************************************************************/
#events {

    @include media-breakpoint-down(md) {
        padding-top: 77px;
        padding-bottom: 77px;
    }

    .ce_text {
        @include media-breakpoint-down(md) {
            padding-top: 77px;
        }
    }

    figure {
        @include media-breakpoint-down(sm) {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
}

/**
unser-garten
*************************************************************/
#unser-garten {
    .ce_text {
        @include media-breakpoint-down(md) {
            padding-bottom: 77px;
        }
    }

    .container {
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }
}




/**
tabs
*************************************************************/

.tab-pane {
    .ce_text {
        p:last-of-type() {
            margin-bottom: 0;
        }

        .inner {
            padding: 30px 60px;
            font-size: 2.2rem;
            line-height: 3rem;
            text-align: center;

            color: #FFFFFF;
        }
    }
}

.nav-tabs {

    border: none !important;

    padding: 40px 20px;

    a,
    h2 {

        font-size: 22px;
        line-height: 30px;
        margin: 0;
        color: #000000;

        border: none !important;
    }

    .nav-link.active,
    .nav-link:hover {
        color: map-get($map: $theme-colors, $key: 'primary');
    }

}

.nav-tabs>li,
.nav-pills>li {
    float: none;
    display: inline-block;
    *display: inline;
    /* ie7 fix */
    zoom: 1;
    /* hasLayout ie7 trigger */
}

.nav-tabs,
.nav-pills {
    text-align: center;
}

/**
slider
*************************************************************/

.slick-next,
.slick-next:focus,
.slick-next:hover,
.slick-prev,
.slick-prev:focus,
.slick-prev:hover {
    height: 35px;
    width: 10px;
    background-image: url('/files/assets/dist/img/slick_arrow.svg');
    background-position: center middle;
    background-repeat: no-repeat;
    background-size: cover;

    &:before {
        font-size: 5rem;
    }

    @include media-breakpoint-down(sm) {
        display: none !important;
    }
}

.slick-prev {
    left: -40px;
}

.slick-next {
    right: -40px;
    transform: rotate(180deg);
}

.slick-dotted.slick-slider {
    margin-bottom: 20px;

}

.slick-dots {
    bottom: -50px;
}

.slick-slide {
    .ce_text {
        text-align: center;
        padding: 0 35px;

        p:last-of-type() {
            margin-bottom: 0;
        }
    }
}

/**
footer
*************************************************************/
#footer {
    padding: 45px 0;
    font-size: 1.5rem;
    line-height: 3rem;
}